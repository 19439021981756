import { NgModule } from '@angular/core';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import  {environment}  from '../../environments/environment'

const config: SocketIoConfig = { url: environment.wsUrl, options: {path: '/ws'} };

@NgModule({
    imports: [SocketIoModule.forRoot(config)],
    exports: [SocketIoModule]
})
export class SocketIoConfigModule {}
